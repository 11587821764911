import * as React from "react";
import * as styles from "./index.less";
import { OverPack } from "rc-scroll-anim";
import QueueAnim from "rc-queue-anim";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import { Link } from "react-router-dom";
import Icon from "../../components/Icon";
import { IFooterProps } from "./IFooterTypes";
import { RbImage } from "rb-design";

const FooterMenu = (props: { pixel: number }) => (
  <div className={styles.footerMenu}>
    <div>
      <RbImage
        style={{
          margin: `auto ${40 * props.pixel}px auto ${100 * props.pixel}px`,
          // marginLeft: 40 * props.pixel,
          // marginRight: 100 * props.pixel,
          width: 356 * props.pixel
        }}
        src={require("../../../assets/ydy_logo.png")}
        alt="源钉云"
      />
      <div className={styles.menuContainer}>
        <div className={styles.menu}>
          <span className={styles.menuTitle}>产品</span>
          <span className={styles.menuSub}>
            <Link to="/yida">易搭</Link>
            <Link to="/hxs">慧经营</Link>
          </span>
        </div>
        <div className={styles.menu}>
          <span className={styles.menuTitle}>更多</span>
          <span className={styles.menuSub}>
            <Link to="/about">关于我们</Link>
            <Link to="/about/contact">联系我们</Link>
            <Link to="/about/join">加入我们</Link>
          </span>
        </div>
        <div className={styles.menu}>
          <span className={styles.menuTitle}>售前咨询热线</span>
          <a
            href="tel://02759766026"
            className={styles.menuTel}
            target="_blank"
            rel="noopener noreferrer"
          >
            027-59766026
          </a>
        </div>
      </div>
    </div>
  </div>
);

class Footer extends React.Component<IFooterProps> {
  public static defaultProps: IFooterProps = { service: [], pixel: 1, isMobile: false };
  render() {
    const { service, pixel, isMobile } = this.props;
    return (
      <>
        <OverPack playScale={0.1}>
          <QueueAnim
            type={isMobile ? "right" : "bottom"}
            key="service"
            leaveReverse={true}
            component={Row}
            componentProps={{ className: styles.service }}
          >
            {service.map((item, i) => {
              return (
                <Col key={i.toString()} className={styles.serviceItem} md={6} xs={24}>
                  <Icon type={item.icon} className={styles.serviceItemIcon} />
                  <span>
                    <span className={styles.serviceItemTitle}>{item.title}</span>
                    <br />
                    <span className={styles.serviceItemContent}>{item.content}</span>
                  </span>
                </Col>
              );
            })}
          </QueueAnim>
        </OverPack>

        {!!!isMobile && <FooterMenu pixel={pixel} />}
        <div className={styles.footer}>
          <div>
            武汉源钉云互联科技有限公司 © 2018{" "}
            <a href="http://www.miitbeian.gov.cn" target="_blank" rel="noopener noreferrer">
              粤ICP备17044531号-4
            </a>
          </div>
        </div>
      </>
    );
  }
}

export default Footer;
