import * as React from "react";
import { enquireScreen } from "enquire-js";
import * as styles from "./Header.module.less";
import { IHeaderProps, IHeaderStates } from "./IHeaderProps";
import Menu, { MenuMode, ClickParam } from "antd/lib/menu";
import { withRouter } from "react-router-dom";
import Layout from "antd/lib/layout";
import Icon from "../Icon";
import { RbImage } from "rb-design";

type getPathFn = (key: string, menus: IMenuDataItem[]) => IMenuDataItem | undefined;
type getCurrentFn = (props: IHeaderProps) => IHeaderStates;

const Logo = (props: { onClick: () => void }) => (
  <div className={styles.logo} onClick={props.onClick}>
    <RbImage
      style={{ width: 140, height: "100%" }}
      src={require("../../../assets/ydy.svg")}
      objectFit="contain"
      alt="源钉云"
    />
  </div>
);
const Login = () => (
  <div className={styles.loginContainer}>
    <a
      className={styles.login}
      href="https://app.yuandingyun.vip/login.html"
      target="_blank"
      rel="noopener noreferrer"
    >
      登录
    </a>
  </div>
);

class Header extends React.Component<IHeaderProps, IHeaderStates> {
  constructor(props: IHeaderProps) {
    super(props);
    this.state = {
      ...this.getCurrent(props),
      isMobile: false,
      menuClassName: `${styles.menu} ${styles.menuHidden}`
    };
    enquireScreen((isMobile: boolean) => {
      this.state = { ...this.state, isMobile };
    });
  }

  componentWillReceiveProps(nextProps: IHeaderProps) {
    this.setState(this.getCurrent(nextProps));
  }

  private getCurrent: getCurrentFn = (props: IHeaderProps) => {
    let pathname = props.location.pathname;
    const pathnameArr = pathname.split("/");
    pathname = pathnameArr[pathnameArr.length - 1];
    const menu = this.getPath(pathname, props.menus);
    if (menu) return { current: menu.exact };
    return { current: props.current };
  };

  private getPath: getPathFn = (key: string, menus: IMenuDataItem[]) => {
    for (const menu of menus) {
      if (menu.children) {
        if (menu.key === key) {
          return menu;
        }
        return this.getPath(key, menu.children);
      } else {
        if (menu.key === key) {
          return menu;
        }
      }
    }
    return undefined;
  };

  private handleClick = (param: ClickParam) => {
    const { menus } = this.props;
    const menu = this.getPath(param.key, menus);
    if (menu) {
      const path = menu.href ? menu.href : menu.path ? menu.path : `/${menu.key}`;
      this.props.history.push(path);
      this.setState({
        current: param.keyPath,
        menuClassName: `${styles.menu} ${styles.menuHidden}`
      });
      return;
    }
  };

  private handleLogoClick = () => {
    // window.location.href = "/";
    this.props.history.push(`/home`);
  };

  private menuClick = () => {
    const { menuClassName } = this.state;
    if (menuClassName === styles.menu) {
      this.setState({ menuClassName: `${styles.menu} ${styles.menuHidden}` });
    } else {
      this.setState({ menuClassName: styles.menu });
    }
  };

  private renderMenu = (mode: MenuMode) => {
    const { menus } = this.props;
    const { current, isMobile } = this.state;
    return (
      <Menu
        onClick={this.handleClick}
        style={{ marginTop: 8 }}
        // className="needsclick"
        selectedKeys={current}
        mode={mode}
      >
        {menus &&
          menus.map(menu => {
            if (menu.children) {
              const onClick = () => {
                const path = menu.path ? menu.path : `/${menu.key}`;
                this.props.history.push(path);
                this.setState({
                  current: menu.exact,
                  menuClassName: `${styles.menu} ${styles.menuHidden}`
                });
              };
              if (isMobile) {
                return menu.children.map(item => {
                  return <Menu.Item key={item.key}>{item.name}</Menu.Item>;
                });
              }
              return (
                <Menu.SubMenu
                  key={menu.key}
                  title={<div className="menu-title">{menu.name}</div>}
                  onTitleClick={onClick}
                >
                  {menu.children.map(item => {
                    return <Menu.Item key={item.key}>{item.name}</Menu.Item>;
                  })}
                </Menu.SubMenu>
              );
            }
            return <Menu.Item key={menu.key}>{menu.name}</Menu.Item>;
          })}
      </Menu>
    );
  };

  public render() {
    const { isMobile, menuClassName } = this.state;
    if (isMobile) {
      const { children, headerClassName, contentClassName } = this.props;
      return (
        <Layout className="layout">
          <Layout.Header className={headerClassName}>
            <div className={styles.menuIcon} onClick={this.menuClick}>
              <Icon type="antd-menu" />
            </div>
            <Logo onClick={this.handleLogoClick} />
            <Login />
          </Layout.Header>
          <Layout.Content className={contentClassName}>{children}</Layout.Content>
          <div className={menuClassName}>{this.renderMenu("inline")}</div>
        </Layout>
      );
    }
    return (
      <>
        <Logo onClick={this.handleLogoClick} />
        <Login />
        {this.renderMenu("horizontal")}
      </>
    );
  }
}

export default withRouter(Header);
