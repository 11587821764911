import * as React from "react";
import { render } from "react-dom";
import { HashRouter as Router, Route } from "react-router-dom";

// AppContainer is a necessary wrapper component for HMR
// import { AppContainer } from 'react-hot-loader'
// import 'lib-flexible';
import "antd/dist/antd.less";
import "./../stylesheets/main.less";

/*
  Main App Container
 */
import App from "./containers/App/App";
const app = (
  <Router>
    <Route component={App} />
  </Router>
);
render(app, document.getElementById("reactContainer"));
