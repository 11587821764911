import * as React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";

type IScrollToTopProps = RouteComponentProps<IDictionary>;

class ScrollToTop extends React.Component<IScrollToTopProps> {
  shouldComponentUpdate(nextProps: IScrollToTopProps) {
    const { hash } = nextProps.location;
    if (hash !== "") {
      // Push onto callback queue so it runs after the DOM is updated,
      // this is required when navigating from a different page so that
      // the element is rendered on the page before trying to getElementById.
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        // if (element) element.scrollIntoView();
        if (element) {
          const offsetTop = element.offsetTop - 80;
          const type = window.scrollY <= offsetTop;
          let start = type ? window.scrollY : offsetTop;
          const end = type ? offsetTop : window.scrollY;
          const interval = setInterval(() => {
            if (start < end) {
              start += 400;
              start = start > end ? end : start;
              window.scrollTo(0, start);
            } else {
              clearInterval(interval);
            }
          }, 50);
        }
      }, 0);
    } else {
      if (this.props.location.pathname !== nextProps.location.pathname) {
        window.scrollTo(0, 0);
      }
    }
    return true;
  }
  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);
