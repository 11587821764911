import * as React from "react";
import { enquireScreen } from "enquire-js";
import * as styles from "./index.less";
import { Route, Switch } from "react-router-dom";
import Layout from "antd/lib/layout";
import { hot } from "react-hot-loader";
import * as Loadable from "react-loadable";
import loading from "../../components/Loading";
import { IAppProps, IAppStates, IMenuDataCallbackFn } from "./IAppProps";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer";
import Go2Top from "../../components/Go2Top";
import ScrollToTop from "../../components/ScrollToTop";
import FooterXf from "../../components/FooterXf";

const Home = Loadable({
  loading,
  loader: () => import(/* webpackChunkName: "Home" */ "../Home")
});
const YiDa = Loadable({
  loading,
  loader: () => import(/* webpackChunkName: "YiDa" */ "../YiDa")
});
const HXS = Loadable({
  loading,
  loader: () => import(/* webpackChunkName: "HXS" */ "../HXS")
});
const About = Loadable({
  loading,
  loader: () => import(/* webpackChunkName: "About" */ "../About")
});
const ContactUs = Loadable({
  loading,
  loader: () => import(/* webpackChunkName: "ContactUs" */ "../ContactUs")
});
const JoinUs = Loadable({
  loading,
  loader: () => import(/* webpackChunkName: "JoinUs" */ "../JoinUs")
});
const resize = "orientationchange" in window ? "orientationchange" : "resize";

const menus: IMenuData = [
  { name: "首页", key: "home", exact: ["home"], component: Home },
  { name: "易搭", key: "yida", exact: ["yida"], component: YiDa },
  { name: "慧经营", key: "hxs", exact: ["hxs"], component: HXS },
  {
    name: "关于我们",
    key: "about",
    path: "/about",
    exact: ["about_index", "about"],
    children: [
      {
        name: "关于我们",
        key: "about_index",
        path: "/about",
        exact: ["about_index", "about"],
        component: About
      },
      {
        name: "联系我们",
        key: "contact",
        path: "/about/contact",
        exact: ["contact", "about"],
        component: ContactUs
      },
      {
        name: "加入我们",
        key: "join",
        path: "/about/join",
        exact: ["join", "about"],
        component: JoinUs
      }
    ]
  }
];
const service = [
  { icon: "antd-free", title: "免费体验", content: "全线产品0元体验" },
  { icon: "antd-train", title: "免费培训", content: "定期免费培训" },
  { icon: "antd-v", title: "VIP服务", content: "每个客户VIP群一对一服务" },
  { icon: "antd-service", title: "7*8", content: "7*8小时在线服务" }
];

class App extends React.Component<IAppProps, IAppStates> {
  constructor(props: IAppProps) {
    super(props);
    this.state = { pixel: this.getPixel(), isMobile: false };
    window.addEventListener(resize, this.componentResize, false);
    enquireScreen((isMobile: boolean) => {
      this.state = { ...this.state, isMobile };
    });
  }

  componentWillUnmount() {
    window.removeEventListener(resize, this.componentResize, false);
  }

  componentResize = () => {
    this.setState({ pixel: this.getPixel() });
  };

  getPixel = () => {
    let width = window.innerWidth;
    width = width > 1024 ? width : 1024;
    const pixel = width > 1024 ? width / 1920 : 0.534;
    return pixel;
  };

  private callbackfn: IMenuDataCallbackFn = (value: IMenuDataItem) => {
    if (value.children) {
      return value.children.map(this.callbackfn);
    } else {
      const path = value.path ? value.path : `/${value.key}`;
      return <Route key={value.key} path={path} exact={true} component={value.component} />;
    }
  };

  private renderMenus = () => {
    return menus.map(this.callbackfn);
  };

  public render() {
    const { pixel } = this.state;
    const { isMobile } = this.state;
    if (isMobile) {
      return (
        <ScrollToTop>
          <Header
            menus={menus}
            current={["home"]}
            headerClassName={`${styles.header} layout`}
            contentClassName={styles.content}
          >
            <Switch>
              <>
                {this.renderMenus()}
                <Route path="/" exact={true} component={Home} />
                {/* <Redirect path="/" exact={true} to="/home" /> */}
              </>
            </Switch>
            <Footer service={service} pixel={pixel} isMobile={isMobile} />
            <FooterXf />
          </Header>
        </ScrollToTop>
      );
    }
    return (
      <ScrollToTop>
        <Layout className="layout">
          <Layout.Header className={`${styles.header} layout`}>
            <Header menus={menus} current={["home"]} />
          </Layout.Header>
          <Layout.Content className={styles.content}>
            <Switch>
              <>
                {this.renderMenus()}
                <Route path="/" exact={true} component={Home} />
                {/* <Redirect path="/" exact={true} to="/home" /> */}
              </>
            </Switch>
            <Footer service={service} pixel={pixel} isMobile={isMobile} />
          </Layout.Content>
          <Go2Top />
        </Layout>
      </ScrollToTop>
    );
  }
}

export default hot(module)(App);
