import * as React from "react";
import { createFromIconfontCN } from "rb-design";
import { IDYIconPropTypes } from "rb-design/dist/Icon";

const TmpIcon = createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/font_905607_pp2472ntts.js" // generated by iconfont.cn
});

class Icon extends React.Component<IDYIconPropTypes> {
  render() {
    const { type } = this.props;
    if (type) {
      if (type.indexOf("antd-") === 0) {
        return <TmpIcon {...this.props} />;
      }
    }
    return <React.Fragment />;
  }
}

export default Icon;
