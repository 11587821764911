import * as React from "react";
import * as styles from "./index.less";
import Icon from "../Icon";

const a =
  "https://h5.dingtalk.com/invite-page/index.html?corpId=dingd9e98946bad0663735c2f4657eb6378f&inviterUid=020AB219DBFDA37B&encodeDeptId=5A760CA820ECB30999372A3FA1525DE6";

class FooterXf extends React.Component {
  render() {
    return (
      <>
        <div style={{ height: 45 }} />
        <div className={styles.footer}>
          <a href="tel:02759766026">
            <Icon type="antd-advisory" />
            <span>联系我们</span>
          </a>
          <a href={a} target="_blank" rel="noopener noreferrer">
            <Icon type="antd-dingding" />
            <span>立刻体验</span>
          </a>
          <div className={styles.line} />
        </div>
      </>
    );
  }
}

export default FooterXf;
