import * as React from "react";
import classNames from "classnames";
import BackTop from "antd/lib/back-top";
import * as styles from "./index.less";
import Icon from "../Icon";
import { IGo2TopProps, IGo2TopStates } from "./IGo2TopTypes";

const menus = [
  { key: "kefu", icon: "antd-kefu", title: "在线客服" },
  { key: "advisory", icon: "antd-advisory", title: "咨询热线" },
  { key: "qrcode", icon: "antd-qrcode", title: "立刻体验" },
  { key: "go2top", icon: "antd-go2top", title: "返回顶部" }
];

class Go2Top extends React.Component<IGo2TopProps, IGo2TopStates> {
  constructor(props: IGo2TopProps) {
    super(props);
    const status: IDictionary<boolean> = {};
    const triangleStatus: IDictionary<boolean> = {};
    status[styles.kefu] = false;
    status[styles.qrcode] = false;
    status[styles.advisory] = false;
    triangleStatus[styles.kefuTriangle] = false;
    triangleStatus[styles.qrcodeTriangle] = false;
    this.state = { status, triangleStatus };
  }

  render() {
    const classValue: IDictionary<boolean> = { ...this.props.className };
    classValue[styles.container] = true;
    return (
      <BackTop className={classNames(classValue)} style={this.props.style}>
        {menus.map((menu, index) => {
          const onMouseOver = () => {
            const status = { ...this.state.status };
            const triangleStatus = { ...this.state.triangleStatus };
            for (const key in status) {
              if (status.hasOwnProperty(key)) {
                status[key] = false;
              }
            }
            for (const key in triangleStatus) {
              if (triangleStatus.hasOwnProperty(key)) {
                triangleStatus[key] = false;
              }
            }
            switch (menu.key) {
              case "kefu":
                status[styles.kefu] = true;
                triangleStatus[styles.kefuTriangle] = true;
                break;
              case "advisory":
                status[styles.advisory] = true;
                break;
              case "qrcode":
                status[styles.qrcode] = true;
                triangleStatus[styles.qrcodeTriangle] = true;
                break;
              default:
                break;
            }
            this.setState({ status, triangleStatus });
          };
          const onMouseOut = () => {
            const status = { ...this.state.status };
            const triangleStatus = { ...this.state.triangleStatus };
            for (const key in status) {
              if (status.hasOwnProperty(key)) {
                status[key] = false;
              }
            }
            for (const key in triangleStatus) {
              if (triangleStatus.hasOwnProperty(key)) {
                triangleStatus[key] = false;
              }
            }
            this.setState({ status, triangleStatus });
          };
          const onClick = (event: any) => {
            if (menu.key !== "go2top") {
              event.stopPropagation();
            }
          };
          return (
            <div
              key={index}
              className={styles.menu}
              onMouseOver={onMouseOver}
              onMouseOut={onMouseOut}
              onClick={onClick}
            >
              <Icon className={styles.menuIcon} type={menu.icon} />
              <span className={styles.menuTitle}>{menu.title}</span>
            </div>
          );
        })}

        <div className={classNames(this.state.status)} />
        <div className={classNames(this.state.triangleStatus)} />
      </BackTop>
    );
  }
}

export default Go2Top;
